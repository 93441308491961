<script setup lang="ts">
import getUserData from "~~/composables/useUserData"
import { type PropType } from "vue"
import { MenuItemType } from "~/types"
const { getWhoAmI } = getUserData()
const userData = await getWhoAmI()
const version = useRuntimeConfig().public.version
const { menuItems } = defineProps({
  menuItems: {
    type: Array as PropType<MenuItemType[]>,
  },
})
const logOut = () => {
  localStorage.setItem("token", "")
  navigateTo("/login/")
}
</script>
<template>
  <!-- Navbar -->
  <div class="w-full navbar bg-orange-600 text-white">
    <div class="flex-none lg:hidden">
      <label for="my-drawer-3" class="btn btn-square btn-ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </label>
    </div>

    <div class="flex-1 px-2 mr-2">
      <NuxtLink to="/" class="font-extrabold normal-case text-xl"
        >Appcia</NuxtLink
      >
      <NuxtLink
        :to="menuItem.to"
        class="hidden md:flex btn btn-ghost normal-case text-xl ml-5 "
        v-for="(menuItem, idx) in menuItems"
        :key="idx"
        >{{ menuItem.name }}</NuxtLink
      >
    </div>
    <div class="flex-none">
      <div class="dropdown dropdown-end" v-if="userData">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar">
          <div class="w-10 rounded-full">
            <img :src="userData.avatar" />
          </div>
        </label>
        <ul
          tabindex="0"
          class="mt-5 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 text-gray-800"
        >
          <li>
            <span class="text-gray-600 font-light">version {{ version }}</span>
          </li>
          <li>
            <NuxtLink to="/profile" class="justify-between">Profil</NuxtLink>
          </li>
          <li>
            <NuxtLink to="'/login" @click.prevent="logOut">Logout</NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Navbar -->
</template>
