<template>
  <div class="drawer bg-gray-300 font-Roboto antialised min-h-screen">
    <input id="my-drawer-3" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content flex flex-col">
        <Navbar :menuItems="menuItems" class=""/>
        <NuxtPage />
    </div>
    <div class="drawer-side">
      <label for="my-drawer-3" class="drawer-overlay"></label>
      <ul class="menu p-4 w-80 bg-base-100">
        <!-- Sidebar content here -->
        <h3 class="text-orange-700 font-bold text-xl mb-5">Babcia Online</h3>
        <li class="border-t-2" v-for="menuItem in menuItems" :key="menuItem.to"><nuxt-link :to="menuItem.to">{{ menuItem.name }}</nuxt-link></li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { WhoAmIResponse, MenuItemType } from '@/types'

const userData: Ref<WhoAmIResponse> | null = useState("userData")

const menuItems: MenuItemType[] = [
  {name: "Start", to: "/"},
  {name: "Bilder", to: "/images/"},
  {name: "Musik", to: "/music/"},
]
</script>
